.accordion__section {
  display: flex;
  flex-direction: column;
}

.accordion {
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.accordion:hover,
.active {
  background-color: #eee;
  color: black;
}

.accordion__title {
  font-weight: 200;
  font-size: 17px;
  text-align: left;
}

.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}

.accordion__content {
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
}


.accordion__text {
  background-color: #eee;
  font-size: 14px;
  padding: 18px;
}