.yellowCard {
  --bg-color: #ffd861;
  --bg-color-light: #ffeeba;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(255, 215, 97, 0.48);
}

.greenCard {
  --bg-color: #B8F9D3;
  --bg-color-light: #e2fced;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(184, 249, 211, 0.48);
}

.pin {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 2px;
  height: 8px;
  border-radius: 3px;
  transform: scale(var(--ggs,1));
  background: currentColor
}
.pin::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  border: 6px double;
  border-radius: 100px;
  top: -10px;
  left: -5px
}


.blueCard {
  --bg-color: #DCE9FF;
  --bg-color-light: #f1f7ff;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(220, 233, 255, 0.48);
}

.card {
  width: 220px;
  height: 321px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0,0,0,0.04);
  transition: all 0.3s ease-out;
  overflow: hidden;
  text-decoration: none;
}

.box {
  box-sizing: border-box;
  float: left;
  width: 45%;
  height: 10%;
}

.column {
  float: left;
  width: 50%;
  padding: 10px;
  height: 25%;
}


.row:after {
  content: "";
  display: table;
  clear: both;
}



.box:first-child {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}
.box:nth-child(2) {
  border-bottom: 1px solid black;
}
.box:nth-child(3) {
  border-right: 1px solid black;
}

.card1 {
  width: 220px;
  height: 321px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  box-shadow: 0 14px 26px rgba(0,0,0,0.04);
  text-decoration: none;
}

.card2 {
  width: 90%;
  height: 321px;
  margin-top: 30px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  box-shadow: 0 14px 26px rgba(0,0,0,0.04);
  text-decoration: none;
}

.actionCard {
  width: 90%;
  height: 400px;
  margin-top: 30px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  box-shadow: 0 14px 26px rgba(0,0,0,0.04);
  text-decoration: none;
}

.spacerCard {
  width: 90%;
  height: 50px;
  margin-top: 30px;
  background: transparent;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  text-decoration: none;
}

.card:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0,0,0,0.11),
    0 24px 46px var(--box-shadow-color);
}

.card:hover .overlay {
  transform: scale(4) translateZ(0);
}

.card:hover .circle {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
}

.card:hover .circle:after {
  background: var(--bg-color-light);
}

.card:hover p {
  color: var(--text-color-hover);
}

.card:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0,0,0,0.11),
    0 15px 24px var(--box-shadow-color);
}

.card p {
  font-size: 17px;
  color: #4C5656;
  margin-top: 30px;
  z-index: 1000;
  transition: color 0.3s ease-out;
}

.circle {
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.circle:after {
  content: "";
  width: 118px;
  height: 118px;
  display: block;
  position: absolute;
  background: var(--bg-color);
  border-radius: 50%;
  top: 7px;
  left: 7px;
  transition: opacity 0.3s ease-out;
}

.circle svg {
  z-index: 10000;
  transform: translateZ(0);
}

.overlay {
  width: 118px;
  position: absolute; 
  height: 118px;
  border-radius: 50%;
  background: var(--bg-color);
  top: 70px;
  left: 50px;
  z-index: 0;
  transition: transform 0.3s ease-out;
}