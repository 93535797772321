* {
  box-sizing: border-box;
}

body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #4A4E69;
}

.a {
  display: flex;
  flex-direction: column;
  width: 400px;
  min-width: 100px;
  min-height: 400px;
  padding: 20px 40px 40px 40px;
  border-radius: 6px;
  box-shadow: 0 14px 26px rgba(0,0,0,0.04);
  background-color: #fefefe;
}

.mail,
.mail::after {
    display: block;
    box-sizing: border-box;
    height: 14px;
    border: 2px solid
}
.mail {
    overflow: hidden;
    transform: scale(var(--ggs,1));
    position: relative;
    width: 18px;
    border-radius: 2px
}
.mail::after {
    content: "";
    position: absolute;
    border-radius: 3px;
    width: 14px;
    transform: rotate(-45deg);
    bottom: 3px;
    left: 0
}

form > h1 {
  display: flex;
  justify-content: center;
  font-size: 4em;
  font-weight: lighter;
  margin-top: 0.25em;
  color: #222;
  letter-spacing: 2px;
}

form > div {
  margin: 0.5em 0;
}

.info {
  padding-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

label {
  color: #444;
  font-weight: lighter;
}

input {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.invalid {
  border: 1px solid #db2269;
  box-shadow: none;
}
input[type="checkbox"].invalid {
  box-shadow: 0px 0px 0px 1px #db2269;
}
input, select {
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid #d6d1d5;
  margin-top: 5px;
  display: block;
}
select {
  width: 100%;
  height: 35px;
}
input[type="checkbox"] {
  display: inline-block;
  width: auto;
  margin-top: 2em;
  margin-right: 10px;
}

button {
  /* min-width: 100%; */
  cursor: pointer;
  margin-right: 0.25em;
  margin-top: 0.5em;
  padding: 	0.938em;
  border: none;
  border-radius: 4px;
  background-color: #22223B;
  color: #fefefe;
}
button:hover {
  background-color: #4A4E69;
  color: #fefefe;
}
button:disabled {
  opacity: 0.2;
}

.required {
  color:#db2269;
  font-size: 0.8em;
  margin-top: 0.5em;
}
